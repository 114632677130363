$csOrange: #BA5C3A;
$csBrown: #A09681;
$csGrey: #808080;

h1 {
    font-size: 3rem;
    font-family: "Archivo Black", "serif";
}

h2 {
    font-family: "Archivo Black", "serif";
    font-size: 2rem;
    text-transform: uppercase;
    color: $csOrange;
}

h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: $csBrown;
}

h4 {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: "Montserrat", "serif";
    text-transform: uppercase;
    color: $csOrange;
}

.personal {
    font-family: "Fira Code", "serif";
    text-align: justify;
}

.pad-v {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.pad-v-sm {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.pad-v-xs {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pad-t {
    padding-top: 3rem;
}

.pad-t-sm {
    padding-top: 2rem;
}

.pad-t-xs {
    padding-top: 1rem;
}

.pad-b-sm {
    padding-bottom: 2rem;
}

.uncolored {
    color: inherit;
}

.mar-b {
    margin-bottom: 1em;
}

.caption {
    text-align: center;
    font-size: 1.15rem;
    font-family: "Arial", "serif";
    font-weight: bold;
    color: grey;
}

.competence-hdr {
    font-weight: bold;
}

.desc {
    text-transform: uppercase;
    font-family: 'Arial', 'serif';
    font-size: 1.15rem;
    font-weight: bold;
    color: $csBrown;
    margin-bottom: 1rem;
}

.font-gothic {
    font-family: 'Questrial', 'sans-serif';
}

.cs-orange {
    color: #BA5C3A;
}

.cs-brown {
    color: #A09681;
}

.cs-lt-grey {
    color: #808080
}
